import { initializeApp, getApps } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check';
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInAnonymously,
	signOut,
	setPersistence,
	browserLocalPersistence,
} from 'firebase/auth';
import axios from 'axios';
import { getFirestore, query, getDocs, collection, where, addDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// eslint-disable-next-line import/no-cycle
import { checkBuyerWithEmailExists, createLog } from './serverCalls';
import { ENV } from './common/data/constants';

// helper function for setting function host
function setFunctionHost() {
	switch (process.env.REACT_APP_CURR_ENV) {
		case ENV.DEVELOPMENT:
			return 'http://localhost:5000/airdealer-67669/us-central1';
		// return 'https://us-central1-airdealer-67669.cloudfunctions.net';
		case ENV.STAGING:
			return 'https://us-central1-ed-staging.cloudfunctions.net';
		case ENV.PRODUCTION:
			return 'https://us-central1-ad-backend-prod.cloudfunctions.net';
		default:
			return 'http://localhost:5000/airdealer-67669/us-central1';
	}
}
const FUNCTION_HOST = setFunctionHost();

// ----- get necessary auth and db objects ----- //
const oemKeyMatch = (app) => app.name === process.env.REACT_APP_OEM_KEY;
let app;
const fbApps = getApps();

// see if the specific app for that oem has already be instantiated - if so, use it - otherwise create it
const appIndex = fbApps.findIndex(oemKeyMatch);
if (appIndex === -1) {
	const appConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	app = initializeApp(appConfig, process.env.REACT_APP_OEM_KEY);
} else {
	app = fbApps[appIndex];
}

// Set up AppCheck for production environment
if (process.env.REACT_APP_CURR_ENV !== ENV.PRODUCTION) {
	// eslint-disable-next-line no-restricted-globals
	self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_VERIFICATION_DEBUG_TOKEN;
}
// eslint-disable-next-line import/no-mutable-exports
let appCheck;
try {
	appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(process.env.REACT_APP_SITE_KEY),
		isTokenAutoRefreshEnabled: true,
	});
} catch (error) {
	throw new Error(`AppCheck not initialized: ${error.message}`);
}

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const { user } = res;
		const q = query(collection(db, 'customer'), where('uid', '==', user.uid));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			await addDoc(collection(db, 'customer'), {
				uid: user.uid,
				name: user.displayName,
				authProvider: 'google',
				email: user.email,
			});
		}
	} catch (err) {
		throw new Error(err);
	}
};

function getRequestHeaders(appCheckToken, authToken) {
	// Add app check token to request headers
	const functionHeaders = { 'X-Firebase-AppCheck': appCheckToken.token };

	if (authToken) {
		functionHeaders.Authorization = `Bearer ${authToken}`;
	}
	return functionHeaders;
}

const createOrGetAccountsForBuyer = async (email, userData, setErrorString) => {
	// sign out of any existing user auth session if one exists
	await signOut(auth);
	const userObj = {
		fullName: userData.fullName,
		address: userData.address,
		phone: userData.phoneNumber,
		oemId: userData.oemId,
		email,
	};
	let buyerId = null;
	let buyerExists = false;
	try {
		// check if email already exists
		const result = await checkBuyerWithEmailExists(email);
		buyerId = result.buyerId;
		buyerExists = result.buyerExists;
		if (buyerExists) {
			// get the existing buyer id
			userObj.id = buyerId;
		} else {
			// create anonymous user with persistence set to LOCAL
			await setPersistence(auth, browserLocalPersistence);
			const { user } = await signInAnonymously(auth);
			// give the user the id of the anon auth user
			userObj.id = user.uid;
		}
	} catch (err) {
		const errorString =
			"We're having difficulty processing your order at the moment. Please try again or contact support for assistance.";
		if (
			err.code === 'auth/email-already-in-use' ||
			err.message === 'auth/email-already-in-use'
		) {
			createLog(
				'Object-creation-failed',
				`Failed to create a new user with error code ${err.code || err.message}`,
				'INFO',
				{ email },
			);
		} else {
			createLog(
				'Object-creation-failed',
				`Failed to create a new user with error code ${err.code || err.message}`,
				'CRITICAL',
				{ email },
			);
		}
		return { error: err.code || err.message, errorString };
	}

	// Now that auth user is created, we must create the firebase user
	// NOTE: If this fails (assuming it got into the function in the first place), the cloud function will automatically delete the auth user
	// Open question: Will that delete operation automatically log the user out as it should?
	if (!buyerExists) {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_oncreate_v2`;
		const body = { newUser: userObj, oemKey: process.env.REACT_APP_OEM_KEY };
		const appCheckToken = await getToken(appCheck, false);
		try {
			await axios.post(url, body, {
				headers: getRequestHeaders(appCheckToken, token),
			});
		} catch (err) {
			// Helpful messaging of error is in the oncreate function -- process different error messages
			if (
				'response' in err &&
				'data' in err.response &&
				(err.response.data.error ===
					'Failed to create buyer in DB: failed to delete associated auth account' ||
					err.response.data.error === 'No OEM name in request')
			) {
				setErrorString(
					"We're having difficulty processing your order at the moment. Please try again with a different email.",
				);
			} else {
				createLog(
					'Object-creation-failed',
					`Failed to create a new user with error code ${err}`,
					'CRITICAL',
				);
				setErrorString(
					"We're having difficulty processing your order at the moment. Please try again.",
				);
			}
			return { error: err };
		}
	}
	return userObj;
};

const logout = () => {
	signOut(auth);
};

export { auth, db, storage, appCheck, signInWithGoogle, createOrGetAccountsForBuyer, logout };
