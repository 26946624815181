import * as Yup from 'yup';
// import { ValidationError } from 'yup'; // Import ValidationError

import moment from 'moment';

export function getGenericAddressValidation(zipExtRequired = false, countyRequired = false) {
	return Yup.object().shape({
		addressString: Yup.string()
			.min(2, 'Too short')
			.max(300, 'Too long')
			.required('Address is required.'),
		addressLineOne: Yup.string()
			.min(2, 'Too short')
			.max(300, 'Too long')
			.required('Street is required.'),
		addressLineTwo: Yup.string().min(2, 'Too short').max(300, 'Too long'),
		city: Yup.string().min(2, 'Too short').max(300, 'Too long').required('City is required.'),
		county: countyRequired
			? Yup.string().min(2, 'Too short').max(300, 'Too long').required('County is required')
			: Yup.string().min(2, 'Too short').max(300, 'Too long'),
		state: Yup.string().required('State is required.'),
		zip: Yup.string()
			.matches(
				zipExtRequired ? /^\d{5}-\d{4}$/ : /^\d{5}(-\d{4})?$/,
				zipExtRequired
					? 'Zip code with 4-digit extension is required.'
					: 'Zip code is required.',
			)
			.required('Zipcode is required.'),
	});
}

export function getPreQualModalValidation() {
	return Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Email is required'),
		firstName: Yup.string().required('First name is required'),
		lastName: Yup.string().required('Last name is required'),
		dob: Yup.string()
			.required('Date of birth is required')
			.test('is-valid-date', 'Date must be in MM/DD/YYYY or YYYY-MM-DD format', (value) => {
				if (!value) return false; // Already covered by required, but just to be safe.
				if (/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value)) {
					const dobVal = moment(value, 'MM/DD/YYYY');
					return dobVal.isValid(); // Check if the date is valid according to moment
				}
				if (/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(value)) {
					const dobVal = moment(value, 'YYYY-MM-DD');
					return dobVal.isValid(); // Check if the date is valid according to moment
				}
				return false; // The format is not correct
			}),
	});
}

export const PRE_QUAL_MODAL_PAGE_NAMES = {
	INFO_COLLECT_PAGE: 'infoCollectPage',
	SUCCESS_PAGE: 'successPage',
	FAILURE_PAGE: 'failurePage',
};
