// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { loadStripe } from '@stripe/stripe-js';
import {
	getVehicleDeliveryEstimate,
	getVehiclePickUpEstimate,
	fetchDefaultFinancingProgram,
	calculateBestMonthlyPayment,
	getDeliveryEstFunc,
} from '@ekhodealer/ekho-common/utils';
import {
	TEMP_TAG_FEE,
	TAR_PROCESS_ENUM,
	PAPERWORK_FEE,
	VI_PROCESSING_FEE,
	CG_PROCESSING_FEE,
	PICK_UP_LOCATION_TYPE_ENUM,
	ORDER_CREATION_TYPES,
	ADDITIONAL_FIELDS_MAP,
	FinancingActionItemOptions,
} from '@ekhodealer/ekho-common';
import { Button, Icon, Spinner } from '@ekhodealer/ekho-common/components';
import _ from 'lodash';

import {
	createAbandonedCart,
	createLog,
	createPlacedOrderObj,
	updateAbandonedCartServerCall,
	updateFinancingActionItem,
} from '../serverCalls';
import { storage, createOrGetAccountsForBuyer } from '../firebase';
import {
	DELIVERY_CHOICES,
	DISCOUNT_TYPES,
	PAYMENT_CHOICES,
	PAYMENT_CONFIG_KEYS,
	DISCOUNT_CATEGORIES,
	DISCOUNT_APPLICABILTY_KEYWORDS,
	PREQUAL_STATUSES,
	DEFAULT_CHECKOUT_CONFIG,
	DELIVERY_DATE_FORMATS,
	CHECKOUT_SECTIONS,
	ENV,
} from '../common/data/constants';
import { PRE_QUAL_MODAL_PAGE_NAMES } from '../components/modals/preQualComponents/PreQualConsts';
import {
	track_buyer_applied_discount,
	track_discount_failed,
	track_faq_link_clicked,
	track_privacy_link_clicked,
	track_terms_link_clicked,
	track_checkout_prequal_modal_opened,
	track_how_it_works_clicked,
	trackingEnabled,
} from './analyticsFuncs';

export function postRedirectMessage(url) {
	// Sending message to the parent window
	window.parent.postMessage(
		{
			type: 'redirect',
			url,
		},
		'*',
	);
}

export function isInIframe() {
	return window !== window.top;
}

// A function passed to the Ekho Dealer Footer component that will be called when the user clicks on the Chat option
export const chatOnClick = () => {
	if (window.Intercom && window.Intercom('booted')) {
		// Check if Intercom is initialized
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	} else if (window.Intercom) {
		// If Intercom isn't initialized, initialize it and show the chat
		window.Intercom('boot', {
			api_base: 'https://api-iam.intercom.io',
			app_id: 'f1a94cfb',
			alignment: 'left',
			horizontal_padding: 50,
			vertical_padding: 50,
			hide_default_launcher: true,
			ekho_app: 'checkout',
			oem_key: process.env.REACT_APP_OEM_KEY,
		});
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	}
};

export function isPurchasePreorder(checkoutCart) {
	return (
		checkoutCart?.orderCreationType === ORDER_CREATION_TYPES.ANTE_PREORDER ||
		checkoutCart?.orderCreationType === ORDER_CREATION_TYPES.ABANDONED_ANTE_PREORDER
	);
}

export function isFinancing(paymentSelection) {
	return paymentSelection === PAYMENT_CHOICES.LOAN_DOWNPAYMENT;
}

export const faqOnClick = (oemKey) => {
	track_faq_link_clicked(oemKey);
	window.open('https://ekhodealer.com/ekho-direct-faqs', '_blank');
};

export const privacyOnClick = (oemKey) => {
	track_privacy_link_clicked(oemKey);
	window.open('https://www.ekhodealer.com/ekho-direct-privacy', '_blank');
};

export const termsOnClick = (oemKey) => {
	track_terms_link_clicked(oemKey);
	window.open('https://www.ekhodealer.com/ekho-direct-terms', '_blank');
};

export const howItWorksOnClick = (oemKey, toggleAboutEkhoModal, isPreorderSession) => {
	toggleAboutEkhoModal(true);
	track_how_it_works_clicked(oemKey, isPreorderSession);
};

export function isDiscountLive(discount) {
	const { startsAt, endsAt } = discount;
	const currentTime = Date.now();
	// Check if the current time is between startsAt and endsAt
	return currentTime >= startsAt && currentTime <= endsAt;
}

export function checkIfActiveDiscountCampaigns(oem) {
	const { discountCampaigns } = oem;
	if (!discountCampaigns) return false;
	return Object.values(discountCampaigns).some((campaign) => isDiscountLive(campaign));
}

export function calculatePreDiscountTotalPriceOfVehicle(vehicle, uniqueAccessories) {
	let preDiscountTotalPrice = vehicle.basePriceWithSpecs;
	if (vehicle.boltOnAccessories) {
		vehicle.boltOnAccessories.forEach((boltOnAccessoryId) => {
			const boltOnAccessory = uniqueAccessories.get(boltOnAccessoryId);
			if (boltOnAccessory) {
				preDiscountTotalPrice += boltOnAccessory.totalPrice;
			}
		});
	}
	return preDiscountTotalPrice;
}

export const debounceSimple = (fn, ms) => {
	let timer;
	return () => {
		clearTimeout(timer);
		timer = setTimeout((...args) => {
			timer = null;
			fn.apply(this, args);
		}, ms);
	};
};

export const calcMaxAccessoryPurchaseWidth = (numElements, accessoryPurchDim, stacked) => {
	return stacked
		? numElements * accessoryPurchDim.width
		: numElements * (accessoryPurchDim.width + 13); // 13 is the margin!
};

export const organizeCarouselImgs = (sortedVehicles, sortedAccessories, accessoryCounts) => {
	if (sortedVehicles.length > 0) {
		const carouselSlides = sortedVehicles.map((vehicle, index) => {
			return {
				src: vehicle.imgUrl,
				altText: vehicle.name,
				captionHeader: '',
				captionText: '',
				key: index,
			};
		});
		return { carouselSlides };
	}
	if (sortedAccessories.size > 0) {
		const [carouselSlides, carouselInfos] = Array.from(sortedAccessories.values()).reduce(
			([slides, infos], accessory) => {
				slides.push({
					src: accessory.imgUrl,
					altText: accessory.name,
					captionHeader: '',
					captionText: '',
					id: accessory.id,
					key: slides.length,
				});
				infos.push({
					name: accessory.name,
					quantity: accessoryCounts.get(accessory.id),
					id: accessory.id,
				});
				return [slides, infos];
			},
			[[], []],
		);
		return { carouselSlides, carouselInfos };
	}
	throw new Error(`Must have at least one item in your cart!`);
};

export function usePrevious(value) {
	const reference = useRef();
	useEffect(() => {
		reference.current = value; // assign the value of ref to the argument
	}, [value]); // this code will run when the value of 'value' changes
	return reference.current; // in the end, return the current ref value.
}

export async function loadSavedImage(filePath) {
	// Create a reference with an initial file path and name
	if (!filePath) return '';
	const pathReference = ref(storage, filePath);
	const imgUrl = await getDownloadURL(pathReference);
	return imgUrl;
}

function idQualifyForDiscount(productId, discount, isMainVehicle) {
	return (
		discount.applicableTo.includes(productId) ||
		discount.applicableTo.includes(DISCOUNT_APPLICABILTY_KEYWORDS.ALL) ||
		(isMainVehicle &&
			discount.applicableTo.includes(DISCOUNT_APPLICABILTY_KEYWORDS.MAIN_VEHICLE))
	);
}

export function getApplicableDiscounts(discounts, productId, isMainVehicle = false) {
	return discounts.filter((discount) => idQualifyForDiscount(productId, discount, isMainVehicle));
}

export function getDiscountAmount(discount, productPrice) {
	const { type, amount } = discount;
	if (type === DISCOUNT_TYPES.PERCENTAGE_DISCOUNT) {
		return productPrice * amount;
	}
	if (type === DISCOUNT_TYPES.FIXED_AMOUNT_DISCOUNT) {
		return amount;
	}
	createLog('Invalid-discount-type', `Invalid discount type: ${type}`, 'ERROR', { discount });
	return 0;
}

export function getCartDataForTracking(
	checkoutCart,
	uniqueAccessories,
	cartCosts,
	selectedDeliveryOption,
	applicableDiscounts,
	placedOrderId = null,
) {
	const contents = [];
	// add delivery or pick up fee
	contents.push({
		quantity: 1,
		description:
			selectedDeliveryOption === DELIVERY_CHOICES.SHIP ? 'Delivery Fee' : 'Pick Up Fee',
		content_id: selectedDeliveryOption,
		content_name:
			selectedDeliveryOption === DELIVERY_CHOICES.SHIP ? 'Delivery Fee' : 'Pick Up Fee',
		price: cartCosts.totalShippingFees + cartCosts.totalPickUpFees,
	});
	// add discounts
	if (cartCosts && cartCosts.totalDiscount > 0) {
		applicableDiscounts.forEach((discount) => {
			let discountString = discount.name;
			if (discount.type === DISCOUNT_TYPES.PERCENTAGE_DISCOUNT) {
				discountString += ` (${toPercentageString(discount.amount)})`;
			}
			const id = discount.reid || discount.campaignId;
			contents.push({
				quantity: 1,
				description: discountString,
				content_id: id,
				content_name: `DISCOUNT: ${discount.name}`,
				discount_type: discount.type,
				discount_category: discount.category,
				category: 'DISCOUNT',
				price: -cartCosts.appliedDiscounts[id],
			});
		});
	}
	// add vehicles from cart
	checkoutCart.vehicles.forEach((vehicle) => {
		const contentItem = {
			content_id: vehicle.productId,
			price: vehicle.basePriceWithSpecs,
			quantity: 1,
			category: 'VEHICLE',
			content_name: vehicle.name,
			variant_id: vehicle.variantId,
		};
		contents.push(contentItem);
	});
	// add accessories from cart
	if (uniqueAccessories) {
		Object.values(uniqueAccessories).forEach((accessory) => {
			const contentItem = {
				content_id: accessory.productId,
				price: accessory.basePrice,
				quantity: accessory.count,
				category: 'ACCESSORY',
				content_name: accessory.name,
				variant_id: accessory.variant_id,
			};
			contents.push(contentItem);
		});
	}
	const data = {
		contents,
		content_type: 'product',
		currency: 'USD',
		value: cartCosts.totalCartCostsPreTaxesAndFees,
		value_with_taxes_and_fees: cartCosts.totalCartCosts,
		delivery_category: selectedDeliveryOption,
		checkout_cart_id: checkoutCart.id,
		order_id: placedOrderId || 'N/A',
		due_today: cartCosts.totalDueToday,
	};
	return data;
}

// Function that calculates all relevant costs and makes them available globally via the cartCosts context.
export const calculateCartCosts = (
	cartVehicles,
	accessoryCounts,
	uniqueAccessories,
	selectedDeliveryOption,
	selectedPaymentOption,
	oemVehicles,
	selectedRegistrationOption,
	stateData,
	oemProfileProps,
	applicableDiscounts,
	registrationState,
	numberOfDotVehicles,
	checkoutCart,
	deliveryDist,
	deliveryState,
	pickUpLocation,
) => {
	// NOTE: You can assume all objects passed in are defined and not null
	// loop through vehicles and calulate total cost of vehicles
	let totalPossibleShippingFees = 0;
	let totalPossiblePickUpFees = 0;
	let totalVehiclesCost = 0; // doesn't include bolt ons, taxes, fees, or shipping
	let totalPossibleVehicleOrderFees = 0;
	let totalPossibleRegistrationFees = 0;
	let totalPossibleFinancingDownpayment = 0;
	let totalPossibleDiscount = 0;
	let totalPossibleFullPaymentDiscount = 0;
	let totalPossibleVIPReservationFees = 0;
	let totalDiscount = 0;
	let totalTempTagFees = 0;

	// temp tags included when a buyer is self registering or when they are registering with us
	// but not when titling only
	if (
		selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED ||
		selectedRegistrationOption === TAR_PROCESS_ENUM.ASSISTED
	) {
		totalTempTagFees = TEMP_TAG_FEE * numberOfDotVehicles;
	}

	const perVehicleCosts = {};
	const paymentConfig = getPaymentConfigOptions(checkoutCart, oemProfileProps);
	const fullPaymentDiscountPerc =
		paymentConfig?.[PAYMENT_CONFIG_KEYS.CHECKOUT_FULL_PAYMENT]?.fullPaymentDiscountPerc || 0;
	const requiredDownPaymentPerc = 0; // getRequiredDownPaymentPerc(paymentConfig);

	const appliedDiscounts = {};
	let combinedVehicleOtherDiscounts = 0;

	// total amount paid before checkout
	let totalPrevPaid = 0;
	const mainVehicleId = cartVehicles.reduce((prev, current) =>
		prev.basePrice > current.basePrice ? prev : current,
	).id;

	let shippingFeesSet = false;
	let pickUpFeesSet = false;
	cartVehicles.forEach((vehicle) => {
		// Find the vehicle in oemVehicles that matches the productId of the cart vehicle
		const oemVehicle = oemVehicles[vehicle.productId];
		let shippingFee = 0;
		let pickUpFee = 0;
		// Get the delivery estimate for the vehicle
		if (selectedDeliveryOption === DELIVERY_CHOICES.SHIP && deliveryDist !== null) {
			shippingFeesSet = true;
			// if shipping fee included in checkout cart, default to this value if already set
			if ('shippingFee' in vehicle) {
				shippingFee = vehicle.shippingFee;
			} else {
				const { customDeliveryEstFunc, customFuncData } = getDeliveryEstFunc(
					oemProfileProps._oemKey,
					oemVehicle,
					deliveryState,
					deliveryDist,
				);
				shippingFee = getVehicleDeliveryEstimate(
					oemVehicle,
					deliveryState,
					customDeliveryEstFunc,
					customFuncData,
				);
			}
		} else if (selectedDeliveryOption === DELIVERY_CHOICES.PICK_UP && pickUpLocation) {
			pickUpFeesSet = true;
			if ('pickUpFee' in vehicle) {
				pickUpFee = vehicle.pickUpFee;
			} else {
				pickUpFee = getVehiclePickUpEstimate(
					oemVehicle,
					pickUpLocation,
					null, // custom pickup func null for now
					null, // custom pickup data null for now
				);
			}
		}
		const { basePriceWithSpecs, orderFee, id: purchasedVehicleId, boltOnAccessories } = vehicle;

		// Check to see if there is an applicable discount for the vehicle
		const isMainVehicle = mainVehicleId === vehicle.id;
		const discounts = getApplicableDiscounts(
			applicableDiscounts,
			vehicle.productId,
			isMainVehicle,
		);

		// Add in the amount paid in previous payments before checkout
		let perVehiclePrevPaymentSum = 0;
		if (vehicle.amountsPaidBeforeCheckout) {
			perVehiclePrevPaymentSum = vehicle.amountsPaidBeforeCheckout.reduce(
				(acc, x) => acc + x.amount,
				0,
			);
			totalPrevPaid += perVehiclePrevPaymentSum;
		}
		let tempTagFee = 0;
		if (oemVehicle.dotVehicle) {
			tempTagFee = TEMP_TAG_FEE;
		}

		// get all the cost of all the accessories that are bolted onto this vehicle (i.e. for each id in the boltOnAccessories array, get the accessory object from the uniqueAccessories map)
		let boltOnAccessoriesCost = 0;
		boltOnAccessories.forEach((boltOnId) => {
			const accessory = uniqueAccessories.get(boltOnId);
			boltOnAccessoriesCost += accessory.totalPrice;
		});
		const preDiscountTotalPrice = basePriceWithSpecs + boltOnAccessoriesCost;
		const fullPaymentDiscount = vehicle.basePrice * fullPaymentDiscountPerc;
		const perVehicleDiscounts = [];
		let vehicleTotalOtherDiscounts = 0;
		discounts.forEach((discount) => {
			const discountAmount = getDiscountAmount(discount, vehicle.basePrice);
			vehicleTotalOtherDiscounts += discountAmount;
			const id = discount.reid || discount.campaignId;
			if (id in appliedDiscounts) {
				appliedDiscounts[id] += discountAmount;
			} else {
				appliedDiscounts[id] = discountAmount;
			}
			// loop through bolt-on accessories and see if any of them qualify for the discount, if so, then include this amount in the per vehicle discounts and totalOtherDiscounts
			let totalBoltOnDiscounts = 0;
			boltOnAccessories.forEach((boltOnId) => {
				if (idQualifyForDiscount(boltOnId, discount)) {
					const accessory = uniqueAccessories.get(boltOnId);
					const boltOnDiscountAmt = getDiscountAmount(discount, accessory.basePrice);
					totalBoltOnDiscounts += boltOnDiscountAmt;
				}
			});
			vehicleTotalOtherDiscounts += totalBoltOnDiscounts;

			// create applied discount obj
			perVehicleDiscounts.push({
				amount: discountAmount + totalBoltOnDiscounts,
				type: discount.type,
				name: discount.name,
				dateApplied: Date.now(),
				category: discount.category,
				reid: discount.reid,
				campaignId: discount.campaignId,
			});
		});

		const salesTax = registrationState
			? preDiscountTotalPrice * stateData[registrationState].taxes.salesTaxPercentage
			: 0;
		const downPayment = Math.max(
			(preDiscountTotalPrice - vehicleTotalOtherDiscounts) * requiredDownPaymentPerc -
				perVehiclePrevPaymentSum,
			0,
		);
		let totalPrice = preDiscountTotalPrice - vehicleTotalOtherDiscounts;
		if (selectedPaymentOption === PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT) {
			totalPrice -= fullPaymentDiscount;
		}
		if (totalPrice < 0) {
			createLog(
				'Invalid-discount-amount',
				`Discount amount is greater than the total price`,
				'CRITICAL',
				{
					discounts,
					vehicle,
				},
			);
			throw new Error('Discount amount is greater than the total price');
		}

		function getTotalPossibleRegistrationFees() {
			let result = 0;
			if (selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED) {
				result +=
					VI_PROCESSING_FEE +
					PAPERWORK_FEE +
					stateData[registrationState].fees.estimatedRegistrationFees +
					(registrationState ? stateData[registrationState].taxes.variableFee || 0 : 0);
			} else if (selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED_TITLING) {
				result +=
					CG_PROCESSING_FEE +
					PAPERWORK_FEE +
					stateData[registrationState].fees.estimatedTitlingFees;
			}
			return result;
		}

		totalPossibleRegistrationFees += getTotalPossibleRegistrationFees();

		// Saving vehicle amounts. Storing these so they can be used when we enirch the vehicles objects before creating the order objects
		const thisVehicleCosts = {
			preDiscountTotalPrice,
			perVehicleDiscounts,
			totalPrice,
			salesTax,
			orderFee,
			downPayment,
			fullPaymentDiscount,
			shippingFee,
			pickUpFee,
			perVehiclePrevPaymentSum,
			tempTagFee,
			totalPossibleRegistrationFees,
		};
		// If the a preorder purchase is being made, optionally add vip reservation fee
		if (
			isPurchasePreorder(checkoutCart) &&
			paymentConfig[PAYMENT_CONFIG_KEYS.PREORDER_VIP_RESERVATION]
		) {
			thisVehicleCosts.vipReservationFee = vehicle.vipOrderFee || 0;
			totalPossibleVIPReservationFees += thisVehicleCosts.vipReservationFee;
		}
		perVehicleCosts[purchasedVehicleId] = thisVehicleCosts;

		// Adjusting totals
		totalVehiclesCost += basePriceWithSpecs;
		totalPossibleVehicleOrderFees += orderFee;
		totalPossibleShippingFees += shippingFee;
		totalPossiblePickUpFees += pickUpFee;
		totalPossibleFullPaymentDiscount += fullPaymentDiscount;
		totalDiscount += vehicleTotalOtherDiscounts;
		combinedVehicleOtherDiscounts += vehicleTotalOtherDiscounts;
	});
	// loop through vehicles and calulate total cost of accessories
	let totalNonBoltOnAccessoryCosts = 0;
	let totalNonBoltOnAccessoryDiscounts = 0;
	let totalBoltOnAccessoryCosts = 0;
	const perAccessoryCosts = {};
	accessoryCounts.forEach((value, key) => {
		const accessory = uniqueAccessories.get(key);
		// don't add bolt on accessories into the due today piece
		const discounts = getApplicableDiscounts(applicableDiscounts, accessory.productId);
		// const discountSum = discounts.reduce((acc, discount) => acc + discount.amount, 0);
		let singleAccessoryDiscountSum = 0;
		discounts.forEach((discount) => {
			// const appliedDiscount = discount.amount * value;
			const discountAmount = getDiscountAmount(discount, accessory.basePrice);
			singleAccessoryDiscountSum += discountAmount;
			const id = discount.reid || discount.campaignId;
			if (id in appliedDiscounts) {
				appliedDiscounts[id] += discountAmount * value;
			} else {
				appliedDiscounts[id] = discountAmount * value;
			}
		});
		if (singleAccessoryDiscountSum > accessory.totalPrice) {
			createLog(
				'Invalid-discount-amount',
				`Discount amount is greater than the total price`,
				'CRITICAL',
				{
					discounts,
					accessory,
				},
			);
			throw new Error('Discount amount is greater than the total price');
		}

		// Intentionally leaving out discounts!
		if (!('boltOnVehicleIds' in accessory)) {
			totalNonBoltOnAccessoryCosts += accessory.totalPrice * value;
			totalNonBoltOnAccessoryDiscounts += singleAccessoryDiscountSum * value;
		} else {
			totalBoltOnAccessoryCosts += accessory.totalPrice * value;
		}
		// Agnostic to the count!
		perAccessoryCosts[key] = {
			preDiscountTotalPrice: accessory.totalPrice,
			totalPrice: accessory.totalPrice - singleAccessoryDiscountSum,
		};
	});
	// Add in discounts to loose accessories
	totalDiscount += totalNonBoltOnAccessoryDiscounts;

	// Now we return the correct total based on the selected delivery option and payment option
	const rawSubtotal =
		totalVehiclesCost + totalNonBoltOnAccessoryCosts + totalBoltOnAccessoryCosts;
	let totalTaxes = registrationState
		? rawSubtotal * stateData[registrationState].taxes.salesTaxPercentage
		: 0;
	const subtotal = rawSubtotal - totalPrevPaid;
	totalPossibleFinancingDownpayment = Math.max(
		(totalVehiclesCost + totalBoltOnAccessoryCosts - combinedVehicleOtherDiscounts) *
			requiredDownPaymentPerc -
			totalPrevPaid,
		0,
	);

	totalPossibleDiscount = totalDiscount + totalPossibleFullPaymentDiscount;
	let totalCartCost = 0;
	let totalDueToday = 0;
	let totalShippingFees = 0;
	let totalPickUpFees = 0;
	let totalRegistrationFees = 0;
	let totalVehicleOrderFees = 0;
	let totalFinancingDownpayment = 0;

	// FIRST: Switch case for deliver option
	switch (selectedDeliveryOption) {
		case DELIVERY_CHOICES.SHIP:
			totalShippingFees = totalPossibleShippingFees;
			totalPickUpFees = 0;
			break;
		case DELIVERY_CHOICES.PICK_UP:
			// doesn't cost anything to ship
			totalShippingFees = 0;
			totalPickUpFees = totalPossiblePickUpFees;
			break;
		default:
			break;
	}
	switch (selectedRegistrationOption) {
		case TAR_PROCESS_ENUM.AUTOMATED:
		case TAR_PROCESS_ENUM.AUTOMATED_TITLING:
			totalRegistrationFees = totalPossibleRegistrationFees;
			break;
		case TAR_PROCESS_ENUM.ASSISTED:
		case TAR_PROCESS_ENUM.ASSISTED_TITLING:
		case TAR_PROCESS_ENUM.NONE:
			totalRegistrationFees = 0;
			totalTaxes = 0;
			break;
		default:
			break;
	}

	// SECOND: Switch case for payment option
	switch (selectedPaymentOption) {
		case PAYMENT_CHOICES.LOAN_DOWNPAYMENT:
			totalVehicleOrderFees = 0;
			totalFinancingDownpayment = totalPossibleFinancingDownpayment;
			// totalDiscount = 0;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes,
				0,
			);

			totalDueToday =
				totalFinancingDownpayment +
				totalNonBoltOnAccessoryCosts -
				totalNonBoltOnAccessoryDiscounts;
			break;
		case PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT:
			totalVehicleOrderFees = 0;
			totalFinancingDownpayment = 0;
			totalDiscount += totalPossibleFullPaymentDiscount;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes,
				0,
			);
			totalDueToday = totalCartCost;
			break;
		case PAYMENT_CHOICES.STANDARD_RESERVATION:
			totalVehicleOrderFees = totalPossibleVehicleOrderFees;
			totalFinancingDownpayment = 0;
			// totalDiscount = 0;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes,
				0,
			);
			totalDueToday =
				totalVehicleOrderFees +
				totalNonBoltOnAccessoryCosts -
				totalNonBoltOnAccessoryDiscounts;
			break;
		case PAYMENT_CHOICES.PREORDER_RESERVATION:
			totalVehicleOrderFees = totalPossibleVehicleOrderFees;
			totalFinancingDownpayment = 0;
			totalCartCost = subtotal;
			totalDueToday = totalVehicleOrderFees;
			break;
		case PAYMENT_CHOICES.PREORDER_VIP_RESERVATION:
			totalVehicleOrderFees = totalPossibleVIPReservationFees;
			totalFinancingDownpayment = 0;
			totalCartCost = subtotal;
			totalDueToday = totalVehicleOrderFees;
			break;
		default:
			break;
	}
	const totalCartCostsPreTaxesAndFees =
		subtotal + totalShippingFees + totalPickUpFees - totalDiscount;

	return {
		// Total cost of all vehicles, non-bolt ons, and bolt ons in the cart, subtracting already paid amounts
		subtotal,
		// Total cost of everything in the cart. Includes taxes, fees, and shipping
		totalCartCost,
		// What the buyer is paying for now. Changes depending on selected payment option. Includes taxes, fees, and shipping
		totalDueToday,
		// Total cost of shipping
		totalShippingFees,
		// Total cost of pickup
		totalPickUpFees,
		// Total taxes (based just on state sales tax percentage)
		totalTaxes,
		// Total cost of registration($0 if buyer is self registering. Otherwise, we use an estimate
		totalRegistrationFees,
		// Total temp tag fees. Simply the number of DOT vehicles times the cost of a temp tag (if the buyer requested one)
		totalTempTagFees,
		// Total cost of vehicle order fees (only not $0 if reservation is selected)
		totalVehicleOrderFees,
		// What the order fees would be if the buyer selected reservation
		totalPossibleVehicleOrderFees,
		// What the VIP reservation fees would be if the buyer selected preorder VIP reservation
		totalPossibleVIPReservationFees,
		// The down payment required (only not $0 if the buyer selected financing)
		totalFinancingDownpayment,
		// Total cost of all loose accessories BEFORE discounts
		totalNonBoltOnAccessoryCosts,
		// Total discounts applied to loose accessories
		totalNonBoltOnAccessoryDiscounts,
		// What the down payment would be if the buyer selected financing
		totalPossibleFinancingDownpayment,
		// The total discount applied to the order
		totalDiscount,
		// Total possible discount
		totalPossibleDiscount,
		// Object containing discount totals per discount
		appliedDiscounts,
		// What the discount would be if the buyer selected cash
		totalPossibleFullPaymentDiscount,
		// The per vehicles costs for each vehicle in the cart. It contains the following fields: preDiscountTotalPrice, totalPrice, salesTax, orderFee, downPayment, discount, shippingFee
		perVehicleCosts,
		// Costs per accessory in the cart. It contains the following fields: preDiscountTotalPrice, totalPrice,
		perAccessoryCosts,
		// The total cost of the cart excluding shipping fees, registration fees, and any taxes. Note: this DOES include discounts!
		totalCartCostsPreTaxesAndFees,
		// The total amount already paid associated with this cart
		totalPrevPaid,
		// Has the shipping address been set and fees accurately calculated?
		shippingFeesSet,
		// Has the pick up location been set and fees accurately calculated?
		pickUpFeesSet,
	};
};

export function calculateMonthlyPayment(loanAmount, term, apr) {
	const realApr = Number(apr) / 100.0;
	const r = Number(realApr) / 12.0;
	const newMonthlyPayment = (loanAmount * r * (1.0 + r) ** term) / ((1.0 + r) ** term - 1.0);
	return newMonthlyPayment;
}

export function extractPhoneNumber(phoneNumber) {
	// Check if input is a string
	if (typeof phoneNumber !== 'string') {
		return '';
	}

	// Remove any non-digit characters
	const digitsOnly = phoneNumber.replace(/\D/g, '');

	// Check if the resulting number has a valid length
	if (digitsOnly.length !== 10) {
		return '';
	}

	return digitsOnly;
}

// Functions used to create the hyperlink warranty text for the services and tempt tags disclosure check
export function createWarrantyDisclosureString(vehicles) {
	// Convert Map values to an array
	const vehiclesArray = Array.from(vehicles.values());

	// Ensure uniqueness based on productId
	const uniqueVehicles = [];
	const productIdsSet = new Set();

	vehiclesArray.forEach((vehicle) => {
		if (!productIdsSet.has(vehicle.productId)) {
			productIdsSet.add(vehicle.productId);
			uniqueVehicles.push(vehicle);
		}
	});

	// Generate individual strings
	const warrantyStrings = uniqueVehicles.map((vehicle) => {
		if (vehicle.warrantyUrl && vehicle.warrantyUrl !== '') {
			return `<a href="${vehicle.warrantyUrl}" target="_blank" rel="noopener noreferrer">the ${vehicle.name}</a>`;
		}
		return `the ${vehicle.name}`;
	});

	// Format the list with commas and 'and'
	let formattedString;
	if (warrantyStrings.length > 1) {
		const last = warrantyStrings.pop();
		formattedString = `${warrantyStrings.join(', ')}, and ${last}`;
	} else {
		// eslint-disable-next-line prefer-destructuring
		formattedString = warrantyStrings[0];
	}
	return formattedString;
}

export function mergeOemAndModelDisclosures(oemProfileProps, oemVehicles, promotion) {
	const allDisclosureEntries = [];
	const { checkoutDisclosures: oemCheckoutDisclosures = [] } = oemProfileProps;
	allDisclosureEntries.push(...oemCheckoutDisclosures);
	// get the values of the oemVehicles object
	Object.values(oemVehicles).forEach((vehicle) => {
		const { checkoutDisclosures = [] } = vehicle;
		allDisclosureEntries.push(...checkoutDisclosures);
	});
	if (promotion) {
		allDisclosureEntries.push({
			name: `'${promotion.name}' Promotion Disclosure`,
			description: promotion.disclaimerText,
		});
	}
	return allDisclosureEntries;
}

export async function checkPaymentIntentCompleted(id) {
	const stripeObj = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
	const paymentIntent = await stripeObj.retrievePaymentIntent(id);
	if (paymentIntent.status === 'succeeded') {
		return true;
	}
	return false;
}

// Function used to create an informative string that describes, based on the accessories bought, which ones will be shipped immediately by the OEM vs which will be bolted onto the vehicle
export function createAccessoriesPurchasedString(oem, uniqueAccessories, accessoryCounts) {
	let numBoltOnAccessories = 0;
	let numNonBoltOnAccessories = 0;
	const listOfBoltOnAccessories = [];
	const listOfNonBoltOnAccessories = [];

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of accessoryCounts.entries()) {
		if (value > 0) {
			const product = uniqueAccessories.get(key);
			if ('boltOnVehicleIds' in product) {
				numBoltOnAccessories += value;
				listOfBoltOnAccessories.push(`${value} ${product.name}`);
			} else {
				numNonBoltOnAccessories += value;
				listOfNonBoltOnAccessories.push(`${value} ${product.name}`);
			}
		}
	}

	let accessoriesPurchasedString = '';
	if (listOfNonBoltOnAccessories.length > 0) {
		const nonBoltOnLabel =
			numNonBoltOnAccessories === 1 ? 'non bolt-on accessory' : 'non bolt-on accessories';
		accessoriesPurchasedString += `Your ${numNonBoltOnAccessories} ${nonBoltOnLabel} (${listOfNonBoltOnAccessories.join(
			', ',
		)}) will be shipped to you immediately by ${oem.name}. `;
	}

	if (listOfBoltOnAccessories.length > 0) {
		const boltOnLabel =
			numBoltOnAccessories === 1 ? 'bolt-on accessory' : 'bolt-on accessories';
		const vehicleString =
			numBoltOnAccessories === 1 ? 'its assigned vehicle' : 'their assigned vehicle(s)';
		accessoriesPurchasedString += `Your ${numBoltOnAccessories} ${boltOnLabel} (${listOfBoltOnAccessories.join(
			', ',
		)}) will be installed on ${vehicleString} by ${oem.name}.`;
	}

	return accessoriesPurchasedString;
}

export function getVehicleNameString(vehicles, currentVehicle, activeVehicleIndex) {
	let vehicleName = 'Vehicle';
	let vehicleNameSuffix = '';
	// array of indexes of where this vehcile type appears
	const vehicleIndexes = {};
	let numVehicles = 0;
	vehicles.forEach((veh, index) => {
		if (veh.productId === currentVehicle.productId) {
			numVehicles += 1;
			vehicleIndexes[index] = numVehicles;
		}
	});

	if (currentVehicle !== null) {
		vehicleName = currentVehicle.name;
		// description = activeVehicleItem.description;
		if (numVehicles > 1) {
			vehicleNameSuffix = ` (${vehicleIndexes[activeVehicleIndex]} of ${numVehicles})`;
		}
	}
	return { vehicleName, vehicleNameSuffix };
}

export function createPurchaseTitleStrings(
	oemProfileProps,
	checkoutCart,
	vehicles,
	currentOemVehicle,
	currentCartVehicle,
	activeVehicleIndex,
	vehicleCheckoutConfig,
) {
	let deliveryTimeStr = 'Loading...';
	const financingProgram = fetchDefaultFinancingProgram(
		oemProfileProps.promotions || {},
		currentCartVehicle.productId,
		currentOemVehicle?.financingPrograms || {},
	);
	let bestMonthlyPayment = null;
	let bestMonthlyPaymentDescription = '';
	// Get the vehicle object from the oemVehicles context. Make sure oemVehicles exists first, then make sure it has a key for the productId

	// Use a static string for delivery expectations on preorders
	if (
		isPurchasePreorder(checkoutCart) &&
		(currentCartVehicle?.preorderProdStart || currentOemVehicle?.preorderProdStart)
	) {
		deliveryTimeStr =
			currentCartVehicle.preorderProdStart ?? currentOemVehicle.preorderProdStart;
	}

	// If the production time, delivery time is set in the vehicle, use these values
	else if (
		currentCartVehicle &&
		'estimatedProductionTime' in currentCartVehicle &&
		'estimatedDeliveryTime' in currentCartVehicle
	) {
		const { estimatedProductionTime = 0, estimatedDeliveryTime = 0 } = currentCartVehicle;
		// please compute the estimated delivery date (today + estimatedProductionTime + estimatedDeliveryTime). These variables are in number of days.  Produce a strin gin the form '2 January 2022'
		deliveryTimeStr = createEstimatedDeliveryString(
			estimatedProductionTime + estimatedDeliveryTime,
		);
	}
	// Otherwise, use value on variant OR (worst case) value on oemVehicle
	else if (currentOemVehicle) {
		const avgProdTime =
			currentOemVehicle?.variants?.[currentCartVehicle?.variantId]?.averageProductionTime ??
			currentOemVehicle?.averageProductionTime;
		const totalLeadTime = avgProdTime + currentOemVehicle.averageDeliveryTime;
		switch (vehicleCheckoutConfig.deliveryDateFormat) {
			case DELIVERY_DATE_FORMATS.DELIVERY_WITHIN: {
				deliveryTimeStr = `within ${totalLeadTime} days`;
				break;
			}
			case DELIVERY_DATE_FORMATS.EST_DELIVERY_DATE:
			default: {
				deliveryTimeStr = createEstimatedDeliveryString(totalLeadTime);
				break;
			}
		}
	}

	const { vehicleName, vehicleNameSuffix } = getVehicleNameString(
		vehicles,
		currentCartVehicle,
		activeVehicleIndex,
	);

	if (currentCartVehicle !== null) {
		const { monthlyPayment, description } = calculateBestMonthlyPayment(
			currentCartVehicle.basePriceWithSpecs,
			financingProgram,
		);
		bestMonthlyPayment = monthlyPayment;
		bestMonthlyPaymentDescription = description;
	}
	return {
		purchaseTitle: `Your ${vehicleName}`,
		titleSuffix: vehicleNameSuffix,
		deliveryTimeStr,
		bestMonthlyPayment,
		bestMonthlyPaymentDescription,
	};
}

export function createStripeStyling() {
	// Get this pre-computed style for use in the stripe component: var(--bs-body-font-family)
	const bodyFontFamily = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-font-family',
	);
	const bodyFontSize = getComputedStyle(document.body).getPropertyValue('--bs-body-font-size');
	const bodyLineHeight = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-line-height',
	);
	const brandColor = getComputedStyle(document.body).getPropertyValue('--brand-color');
	const brandColorLight = getComputedStyle(document.body).getPropertyValue('--brand-color-light');
	const bodyFontWeight = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-font-weight',
	);
	const appearance = {
		theme: 'flat',
		variables: {
			colorBackground: '#f8f9fa',
			primaryColor: brandColor,
			fontSizeBase: bodyFontSize,
			fontFamily: bodyFontFamily,
			fontColor: '#323232',
			fontWeightNormal: '600',
			fontLineHeight: bodyLineHeight,
			borderRadius: '0.625rem',
			colorDanger: '#f35421',
		},
		rules: {
			'.Label': {
				color: '#6C757d',
				fontWeight: '500',
				marginBottom: '0.5rem',
			},
			'.Input': {
				border: '1px solid #f8f9fa',
				boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
				padding: '0.625rem 1.25rem',
				lineHeight: '1.5',
			},
			'.Input:focus': {
				borderColor: brandColor,
				boxShadow: `inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 8px ${brandColorLight}`,
			},
			'.Input--invalid': {
				border: '1px solid #f35421',
				boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
			},
			'.Error': {
				color: '#f35421',
				fontSize: '11.375px',
				fontWeight: bodyFontWeight,
			},
		},
	};
	return appearance;
}

export function createEstimatedDeliveryString(totalLeadTimeInDays) {
	const estimatedDeliveryDate = new Date(Date.now() + totalLeadTimeInDays * 24 * 60 * 60 * 1000);
	return estimatedDeliveryDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
}

export function createBestTimeUntilString(numDays) {
	let waitTime = '';
	// averageProductionTime is in days
	// Convert to weeks or months if necessary
	if (numDays >= 84) {
		waitTime = `${Math.round(numDays / 30)} months`;
	} else if (numDays >= 28) {
		waitTime = `${Math.round(numDays / 7)} weeks`;
	} else if (numDays === 0) {
		waitTime = `No wait time!`;
	} else {
		// account for 1 day vs multiple days
		waitTime = `${numDays} day${numDays > 1 ? 's' : ''}`;
	}
	return waitTime;
}

/* PLACING ORDER FUNCTIONS START HERE */

// We only collect one address from the buyer, so we need to determine which address to use
export function getBuyerAddress(
	registrationAddress,
	selectedDeliveryOption,
	shippingAddressFormik,
) {
	let buyerAddress = null;

	if (selectedDeliveryOption === DELIVERY_CHOICES.SHIP) {
		buyerAddress = {
			addressLineOne: shippingAddressFormik.values.addressLineOne,
			addressLineTwo: shippingAddressFormik.values.addressLineTwo,
			city: shippingAddressFormik.values.city,
			state: shippingAddressFormik.values.state,
			county: shippingAddressFormik.values.county || '',
			zip: shippingAddressFormik.values.zip,
		};
	} else if (selectedDeliveryOption === DELIVERY_CHOICES.PICK_UP) {
		// If pick up, use the registration address

		buyerAddress = {
			addressLineOne: registrationAddress.addressLineOne,
			addressLineTwo: registrationAddress.addressLineTwo,
			city: registrationAddress.city,
			state: registrationAddress?.state,
			county: registrationAddress.county || '',
			zip: registrationAddress.zip,
		};
	}
	return buyerAddress;
}

export function getRegistrationAddress(
	registrationAddressFormik,
	shippingAddressFormik,
	regAddressSameAsShipping,
	selectedDeliveryOption,
) {
	let registrationAddress = null;
	if (regAddressSameAsShipping && selectedDeliveryOption === DELIVERY_CHOICES.SHIP) {
		registrationAddress = {
			addressString: shippingAddressFormik.values.addressString,
			addressLineOne: shippingAddressFormik.values.addressLineOne,
			addressLineTwo: shippingAddressFormik.values.addressLineTwo,
			city: shippingAddressFormik.values.city,
			state: shippingAddressFormik.values.state,
			county: shippingAddressFormik.values.county,
			zip: shippingAddressFormik.values.zip,
		};
	} else {
		registrationAddress = {
			addressString: registrationAddressFormik.values.addressString,
			addressLineOne: registrationAddressFormik.values.addressLineOne,
			addressLineTwo: registrationAddressFormik.values.addressLineTwo,
			city: registrationAddressFormik.values.city,
			state: registrationAddressFormik.values.state,
			county: registrationAddressFormik.values.county,
			zip: registrationAddressFormik.values.zip,
		};
	}
	return registrationAddress;
}

export function getMailingAddress(
	mailingAddressFormik,
	shippingAddressFormik,
	mailingAddressSameAsShipping,
	selectedDeliveryOption,
) {
	let mailingAddress = null;
	if (mailingAddressSameAsShipping && selectedDeliveryOption === DELIVERY_CHOICES.SHIP) {
		mailingAddress = {
			addressString: shippingAddressFormik.values.addressString,
			addressLineOne: shippingAddressFormik.values.addressLineOne,
			addressLineTwo: shippingAddressFormik.values.addressLineTwo,
			city: shippingAddressFormik.values.city,
			state: shippingAddressFormik.values.state,
			county: shippingAddressFormik.values.county || '',
			zip: shippingAddressFormik.values.zip,
		};
	} else {
		mailingAddress = {
			addressString: mailingAddressFormik.values.addressString,
			addressLineOne: mailingAddressFormik.values.addressLineOne,
			addressLineTwo: mailingAddressFormik.values.addressLineTwo,
			city: mailingAddressFormik.values.city,
			state: mailingAddressFormik.values.state,
			county: mailingAddressFormik.values.county || '',
			zip: mailingAddressFormik.values.zip,
		};
	}
	return mailingAddress;
}

export function handleError(intent, setErrorString, result = null) {
	createLog(
		'Checkout-payment-error',
		`Payment error in checkout for payment intent with status ${
			intent?.status
		}. Result was ${JSON.stringify(result)}`,
		'ERROR',
		{ placedOrderId: intent?.metadata?.order_id, paymentIntentId: intent?.id },
	);
	if (intent?.status === 'requires_payment_method' || result?.error) {
		const error = intent?.last_payment_error || result.error || { type: 'unknown_error' };
		// can show errors to customers if they are card errors
		if (error.type === 'card_error') {
			// Not clear if we are allowed to share the decline code with the customer
			// Not sharing for the time being
			// if (error.decline_code) {
			//  setErrorString(
			//      `There was an error processing your payment. ${error.message} Your bank provided the following information about the error: ${error.decline_code}`,
			//  );
			// } else {
			setErrorString(`There was an error processing your payment. ${error.message}`);
			// }
		} else {
			setErrorString(
				'There was an error processing your payment. Please check your payment details, shipping address, and billing address for any mistakes. Otherwise, please try again with a different payment method or card, or at another time.',
			);
		}
	} else {
		createLog(
			'Unrecognized-checkout-payment-error',
			`Buyer will receive no information. Payment error in checkout for payment intent with id ${
				intent?.id
			}. Result object: ${JSON.stringify(result)}`,
			'CRITICAL',
			{ placedOrderId: intent?.metadata?.order_id, paymentIntentId: intent?.id },
		);
	}
}

export function applyDiscountCode(code, oem, applicableDiscounts, setApplicableDiscounts) {
	const { discountCampaigns } = oem;
	const alreadyIncluded = applicableDiscounts.some((discount) => discount.campaignId === code);
	if (
		discountCampaigns &&
		code in discountCampaigns &&
		isDiscountLive(discountCampaigns[code]) &&
		!alreadyIncluded &&
		applicableDiscounts.length === 0 // no stacking of discounts allowed
	) {
		const discountToAdd = {
			name: discountCampaigns[code].name,
			type: discountCampaigns[code].type,
			amount: discountCampaigns[code].amount,
			applicableTo: discountCampaigns[code].applicableTo,
			reid: null,
			campaignId: discountCampaigns[code].id,
			category: DISCOUNT_CATEGORIES.DISCOUNT_CAMPAIGN,
		};
		setApplicableDiscounts([...applicableDiscounts, discountToAdd]);
		// Segment event to track when a buyer applies a discount code
		track_buyer_applied_discount(oem, code);
		return true;
	}
	track_discount_failed(oem, code);
	return false;
}

/* END OF PLACING ORDER FUNCTIONS */
export function createStringFromAddress(address) {
	let addressString = address.addressLineOne ? address.addressLineOne : '';
	if ('addressLineTwo' in address && address.addressLineTwo) {
		addressString += ` ${address.addressLineTwo}`;
	}
	if ('city' in address && address.city) {
		addressString += `, ${address.city}`;
	}
	if ('state' in address && address.state) {
		addressString += `, ${address.state}`;
	}
	if ('zip' in address && address.zip) {
		addressString += ` ${address.zip}`;
	}
	return addressString;
}

export function createSimpleStringFromAddress(address) {
	// only return the city, state, and zip
	let addressString = '';
	if ('city' in address && address.city) {
		addressString += `${address.city}`;
	}
	if ('state' in address && address.state) {
		addressString += `, ${address.state}`;
	}
	if ('zip' in address && address.zip) {
		addressString += ` ${address.zip}`;
	}
	return addressString;
}

export function pickUpLocsContainOemLocs(oemPickUpLocations) {
	// see if any of the pick up locations have type === 'OEM'
	return oemPickUpLocations.some((loc) => loc.type === PICK_UP_LOCATION_TYPE_ENUM.OEM);
}

export function generatePickUpLocName(pickUpLoc) {
	if (pickUpLoc.type === PICK_UP_LOCATION_TYPE_ENUM.EKHO) {
		let addressString = '';
		if (pickUpLoc.city) {
			addressString += `${pickUpLoc.city}`;
		}
		if (pickUpLoc.state) {
			addressString += `, ${pickUpLoc.state}`;
		}
		return `${addressString} Pick Up Location`;
	}
	return `${pickUpLoc.name}`;
}

export function getMilesBetweenCoords(coord1, coord2) {
	const toRadians = (angle) => angle * (Math.PI / 180);

	const earthRadiusKm = 6371;

	const dLat = toRadians(coord2.lat - coord1.lat);
	const dLon = toRadians(coord2.lng - coord1.lng);

	const lat1 = toRadians(coord1.lat);
	const lat2 = toRadians(coord2.lat);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const distanceKm = earthRadiusKm * c;
	return distanceKm * 0.621371; // Convert km to miles
}

export function createRegistrationSectionDescription(vehicles) {
	let description = '';
	if (vehicles.length === 1) {
		description = 'Let us know where your vehicle will be registered.';
	} else {
		description = 'Let us know where your vehicles will be registered.';
	}
	return description;
}

export function cartContainsThisTarAgent(oemVehicles, thisTarAgent, selectedRegistrationOption) {
	// eslint-disable-next-line no-restricted-syntax
	for (const oemVehicle of Object.values(oemVehicles)) {
		const { registrationConfig } = oemVehicle;
		const oemVehicleRegObject = registrationConfig[selectedRegistrationOption];
		if (!oemVehicleRegObject) {
			return false;
		}
		const { tarAgent } = oemVehicleRegObject;
		if (tarAgent && tarAgent === thisTarAgent) {
			return true;
		}
	}
	return false;
}

export function createRefundPolicyString(selectedPaymentOption) {
	const documents = `the Purchase Agreement${
		selectedPaymentOption === PAYMENT_CHOICES.STANDARD_RESERVATION ||
		selectedPaymentOption === PAYMENT_CHOICES.LOAN_DOWNPAYMENT
			? ' or accepting your financing offer'
			: ''
	}`;

	return `You acknowledge and agree that, after placing your order below and signing ${documents}, you are ineligibile for any refund. Before then, you can request a refund by emailing support@ekhodealer.com.`;
}

export function findDaysUntilLatestShippingTime(cartVehicles, oemVehicles) {
	// in the array, find the vehicle with the biggest .averageDeliveryTime
	let maxTime = 0;
	// initialize to the first vehicle

	// get an array from all the values in the oemVehicles object
	const oemVehiclesArray = Object.values(oemVehicles);
	cartVehicles.forEach((vehicle) => {
		if ('estimatedDeliveryTime' in vehicle) {
			const deliveryTime = vehicle.estimatedDeliveryTime || 0;
			if (deliveryTime > maxTime) {
				maxTime = deliveryTime;
			}
		} else {
			const matchingOemVehicle = oemVehiclesArray.find(
				(oemVehicle) => oemVehicle.productId === vehicle.productId,
			);
			if (matchingOemVehicle.averageDeliveryTime > maxTime) {
				maxTime = matchingOemVehicle.averageDeliveryTime;
			}
		}
	});
	return maxTime;
}

export function toPercentageString(num) {
	if (!num) return '0%';
	const percentage = num * 100;
	// Check if the percentage is a whole number
	if (percentage % 1 === 0) {
		return `${percentage}%`; // Return without decimals
	}
	return `${percentage.toFixed(2)}%`; // Keep two decimal places
}

// this function returns {automatedTAR: bool, assistedTAR: bool, automatedTARMessage: string, assistedTARMessage: string}
export function regOptionsSupportedForState(
	oemProfileProps, // must include oemName, oemStatesSupported, websiteUrl
	stateAbbrev,
	selectedStateData,
	uniqueCartVehicleProductIds,
	oemVehicles,
) {
	const { name: oemName, statesSupported: oemStatesSupported, websiteUrl } = oemProfileProps;
	const stateSupported = oemStatesSupported[stateAbbrev];
	if (!stateSupported) {
		const errMsg = `Ekho Dealer does not yet support ${oemName} sales in ${stateAbbrev}. Please select a different state or navigate to ${oemName}'s website for more options.`;
		return {
			regOptionsSupported: [],
			automatedTARMessage: errMsg,
			assistedTARMessage: errMsg,
			automatedTitlingMessage: errMsg,
			assistedTitlingMessage: errMsg,
			noneMessage: errMsg,
		};
	}
	const { tarProcesses } = selectedStateData;
	const {
		automatedTARSupported,
		assistedTARNotSupported,
		automatedTitlingSupported,
		assistedTitlingNotSupported,
		noneSupported,
	} = tarProcesses;
	// For each cartVehicleProductIds, find the vehicle in oemVehicles that matches the productId. The, check that the oemVehicle.vehicleType is contained in the automatedTARSupported array and not in the assistedTARNotSupported array. Do the same for fuelType.

	const regOptionsSupported = [];
	let automatedTARMessage = '';
	let assistedTARMessage = '';
	let automatedTitlingMessage = '';
	let assistedTitlingMessage = '';
	let noneMessage = '';
	const { vehicleTypes: automatedTARVehicleTypes, fuelTypes: automatedTARFuelTypes } =
		automatedTARSupported;
	const {
		vehicleTypes: assistedTARVehicleTypesNotSupported,
		fuelTypes: assistedTARFuelTypesNotSupported,
	} = assistedTARNotSupported;
	const { vehicleTypes: automatedTitlingVehicleTypes, fuelTypes: automatedTitlingFuelTypes } =
		automatedTitlingSupported;
	const {
		vehicleTypes: assistedTitlingVehicleTypesNotSupported,
		fuelTypes: assistedTitlingFuelTypesNotSupported,
	} = assistedTitlingNotSupported;
	const { vehicleTypes: noneVehicleTypesSupported, fuelTypes: noneFuelTypesSupported } =
		noneSupported;
	// eslint-disable-next-line consistent-return
	uniqueCartVehicleProductIds.forEach((productId) => {
		// find the OEM vehicle where oemVehicle.productId === productId

		const oemVehicle = oemVehicles[productId];
		if (!oemVehicle) {
			// Throw an error if the oemVehicle is not found
			createLog(
				'Invalid-vehicle-id',
				`Vehicle with productId ${productId} not found in oemVehicles`,
				'CRITICAL',
				{
					productId,
					oemVehicles,
				},
			);
			const errMsg = `An error has occurred. Please select a different state or navigate to ${oemName}'s website for more options.`;
			return {
				regOptionsSupported: [],
				automatedTARMessage: errMsg,
				assistedTARMessage: errMsg,
				automatedTitlingMessage: errMsg,
				assistedTitlingMessage: errMsg,
				noneMessage: errMsg,
			};
		}
		const { vehicleType, fuelType, registrationConfig } = oemVehicle;
		// First, let's determine automatedTAR
		if (
			automatedTARVehicleTypes.includes(oemVehicle.vehicleType) &&
			automatedTARFuelTypes.includes(oemVehicle.fuelType) &&
			registrationConfig &&
			registrationConfig[TAR_PROCESS_ENUM.AUTOMATED]
		) {
			regOptionsSupported.push(TAR_PROCESS_ENUM.AUTOMATED);
		} else {
			automatedTARMessage = `Ekho does not yet support automated titling and registration for one or more of your selected ${oemName} vehicles in ${selectedStateData.name}. Please select a different state, different registration option, or navigate to ${websiteUrl} for more options.`;
		}
		// Next, let's determine assistedTAR
		if (
			!assistedTARVehicleTypesNotSupported.includes(vehicleType) &&
			!assistedTARFuelTypesNotSupported.includes(fuelType) &&
			registrationConfig &&
			registrationConfig[TAR_PROCESS_ENUM.ASSISTED]
		) {
			regOptionsSupported.push(TAR_PROCESS_ENUM.ASSISTED);
		} else {
			assistedTARMessage = `Ekho does not yet support assisted titling and registration for one or more of your selected ${oemName} vehicles in ${selectedStateData.name}. Please select a different state, different registration option, or navigate to ${websiteUrl} for more options.`;
		}
		// Next, let's determine automatedTitlingSupported
		if (
			automatedTitlingVehicleTypes.includes(oemVehicle.vehicleType) &&
			automatedTitlingFuelTypes.includes(oemVehicle.fuelType) &&
			registrationConfig &&
			registrationConfig[TAR_PROCESS_ENUM.AUTOMATED_TITLING]
		) {
			regOptionsSupported.push(TAR_PROCESS_ENUM.AUTOMATED_TITLING);
		} else {
			automatedTitlingMessage = `Ekho does not yet support automated titling for one or more of your selected ${oemName} vehicles in ${selectedStateData.name}. Please select a different state, different registration option, or navigate to ${websiteUrl} for more options.`;
		}
		// Next, let's determine assistedTitlingNotSupported
		if (
			!assistedTitlingVehicleTypesNotSupported.includes(vehicleType) &&
			!assistedTitlingFuelTypesNotSupported.includes(fuelType) &&
			registrationConfig &&
			registrationConfig[TAR_PROCESS_ENUM.ASSISTED_TITLING]
		) {
			regOptionsSupported.push(TAR_PROCESS_ENUM.ASSISTED_TITLING);
		} else {
			assistedTitlingMessage = `Ekho does not yet support assisted titling for one or more of your selected ${oemName} vehicles in ${selectedStateData.name}. Please select a different state, different registration option, or navigate to ${websiteUrl} for more options.`;
		}
		if (
			noneVehicleTypesSupported.includes(oemVehicle.vehicleType) &&
			noneFuelTypesSupported.includes(oemVehicle.fuelType) &&
			registrationConfig &&
			registrationConfig[TAR_PROCESS_ENUM.NONE]
		) {
			regOptionsSupported.push(TAR_PROCESS_ENUM.NONE);
		} else {
			noneMessage = `Ekho does not yet support opting out of a tilting/registration for one or more of your selected ${oemName} vehicles in ${selectedStateData.name}. Please select a different state, different registration option, or navigate to ${websiteUrl} for more options.`;
		}
	});
	return {
		regOptionsSupported,
		automatedTARMessage,
		assistedTARMessage,
		automatedTitlingMessage,
		assistedTitlingMessage,
		noneMessage,
	};
}

export async function triggerOrderDetailsAbandonedCartUpdate(
	abandonedCartId,
	accountDetailsFormik,
	shippingAddressFormik,
	registrationAddressFormik,
	selectedDeliveryOption,
	selectedRegistrationOption,
	selectedPaymentOption,
	selectedPickUpLocation,
	regAddressSameAsShipping,
	abandonedCartData,
	setAbandonedCartData,
) {
	const { fullName, emailAddress, phoneNumber } = accountDetailsFormik.values;
	const {
		addressString: shippingAddressString,
		addressLineOne: shippingAddressLineOne,
		addressLineTwo: shippingAddressLineTwo,
		city: shippingAddressCity,
		state: shippingAddressState,
		county: shippingAddressCounty,
		zip: shippingAddressZip,
	} = shippingAddressFormik.values;
	const {
		addressString: registrationAddressString,
		addressLineOne: registrationAddressLineOne,
		addressLineTwo: registrationAddressLineTwo,
		city: registrationAddressCity,
		state: registrationAddressState,
		county: registrationAddressCounty,
		zip: registrationAddressZip,
	} = registrationAddressFormik.values;
	const abandonedCartUpdate = {
		id: abandonedCartId,
		customLinkConfig: {
			prefilledInfo: {
				shippingAddress: {},
				registrationAddress: {},
			},
		},
	};
	if (registrationAddressState) {
		abandonedCartUpdate.registrationState = registrationAddressState;
	}
	if (fullName) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerFullName = fullName;
	}
	if (emailAddress) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerEmail = emailAddress;
	}
	if (phoneNumber) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerPhone = phoneNumber;
	}
	if (selectedPaymentOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedPaymentOption =
			selectedPaymentOption;
	}
	if (selectedDeliveryOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedDeliveryOption =
			selectedDeliveryOption;
	}
	if (selectedRegistrationOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedRegistrationOption =
			selectedRegistrationOption;
	}
	if (typeof regAddressSameAsShipping === 'boolean') {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.regAddressSameAsShipping =
			regAddressSameAsShipping;
	}
	if (shippingAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressString =
			shippingAddressString;
	}
	if (shippingAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressLineOne =
			shippingAddressLineOne;
	}
	if (shippingAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressLineTwo =
			shippingAddressLineTwo;
	}
	if (shippingAddressCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.city =
			shippingAddressCity;
	}
	if (shippingAddressState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.state =
			shippingAddressState;
	}
	if (shippingAddressCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.county =
			shippingAddressCounty;
	}
	if (shippingAddressZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.zip = shippingAddressZip;
	}
	if (registrationAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressString =
			registrationAddressString;
	}
	if (registrationAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressLineOne =
			registrationAddressLineOne;
	}
	if (registrationAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressLineTwo =
			registrationAddressLineTwo;
	}
	if (registrationAddressCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.city =
			registrationAddressCity;
	}
	if (registrationAddressState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.state =
			registrationAddressState;
	}
	if (registrationAddressCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.county =
			registrationAddressCounty;
	}
	if (registrationAddressZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.zip =
			registrationAddressZip;
	}
	if (selectedPickUpLocation?.id) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedPickUpLocationId =
			selectedPickUpLocation.id;
	}
	// If the abandonedCartUpdate is the same as the abandonedCartData, don't update the abandoned cart
	if (_.isEqual(abandonedCartUpdate, abandonedCartData)) {
		return { message: 'No changes detected. Not updating abandoned cart.' };
		// eslint-disable-next-line no-else-return
	}
	setAbandonedCartData(abandonedCartUpdate);
	const updateRes = await updateAbandonedCartServerCall(abandonedCartId, abandonedCartUpdate);
	return updateRes;
}

export async function triggerCreditAppAbandonedCartUpdate(
	abandonedCartId,
	applicantDetailsFormik,
	applicantEmploymentFormik,
	abandonedCartData,
	setAbandonedCartData,
) {
	const {
		addressString,
		addressLineOne,
		addressLineTwo,
		city,
		county,
		state,
		zip,
		maritalStatus,
		housingOwnership,
		homeMoveInDate,
		monthlyAmt,
		noteToLender,
		homeAddressSameAsIdAddress,
		loanTerm,
		downpaymentAmt,
	} = applicantDetailsFormik.values;

	const {
		employerName,
		jobStartDate,
		monthlyGrossIncome,
		occupation,
		addressString: employerAddressString,
		addressLineOne: employerAddressLineOne,
		addressLineTwo: employerAddressLineTwo,
		city: employerCity,
		county: employerCounty,
		state: employerState,
		zip: employerZip,
		employmentStatus,
		employerPhoneNumber,
		otherIncomes,
	} = applicantEmploymentFormik.values;

	const abandonedCartUpdate = {
		id: abandonedCartId,
		customLinkConfig: {
			prefilledInfo: {
				creditApplication: {
					applicantDetails: {},
					employmentDetails: {},
				},
			},
		},
	};

	/* Applicant Details */
	if (addressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressString =
			addressString;
	}
	if (addressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressLineOne =
			addressLineOne;
	}
	if (addressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressLineTwo =
			addressLineTwo;
	}
	if (city) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.city =
			city;
	}
	if (county) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.county =
			county;
	}
	if (state) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.state =
			state;
	}
	if (zip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.zip =
			zip;
	}
	if (maritalStatus) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.maritalStatus =
			maritalStatus;
	}
	if (housingOwnership) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.housingOwnership =
			housingOwnership;
	}
	if (homeMoveInDate) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.homeMoveInDate =
			homeMoveInDate;
	}
	if (monthlyAmt) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.monthlyAmt =
			monthlyAmt;
	}
	if (noteToLender) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.noteToLender =
			noteToLender;
	}
	if (homeAddressSameAsIdAddress) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.homeAddressSameAsIdAddress =
			homeAddressSameAsIdAddress;
	}
	if ('loanTerm' in applicantDetailsFormik.values) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.loanTerm =
			loanTerm;
	}
	if ('downpaymentAmt' in applicantDetailsFormik.values) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.downpaymentAmt =
			downpaymentAmt;
	}

	/* Applicant Emnployment Details */
	if (employerName) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employerName =
			employerName;
	}
	if (jobStartDate) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.jobStartDate =
			jobStartDate;
	}
	if (monthlyGrossIncome) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.monthlyGrossIncome =
			monthlyGrossIncome;
	}
	if (occupation) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.occupation =
			occupation;
	}
	if (employerAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressString =
			employerAddressString;
	}
	if (employerAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressLineOne =
			employerAddressLineOne;
	}
	if (employerAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressLineTwo =
			employerAddressLineTwo;
	}
	if (employerCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.city =
			employerCity;
	}
	if (employerCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.county =
			employerCounty;
	}
	if (employerState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.state =
			employerState;
	}
	if (employerZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.zip =
			employerZip;
	}
	if (employmentStatus) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employmentStatus =
			employmentStatus;
	}
	if (employerPhoneNumber) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employerPhoneNumber =
			employerPhoneNumber;
	}
	if (otherIncomes) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.otherIncomes =
			otherIncomes;
	}

	// If the abandonedCartUpdate is the same as the abandonedCartData, don't update the abandoned cart
	if (_.isEqual(abandonedCartUpdate, abandonedCartData)) {
		return { message: 'No changes detected. Not updating abandoned cart.' };
		// eslint-disable-next-line no-else-return
	}
	setAbandonedCartData(abandonedCartUpdate);
	const updateRes = await updateAbandonedCartServerCall(abandonedCartId, abandonedCartUpdate);
	return updateRes;
}

// export async function triggerAbandonedCartCreditApplicationUpdate()

// eslint-disable-next-line consistent-return
function customComparator(objValue, othValue, key) {
	// Ignore 'dateApplied' within 'paymentInfo.discounts' in 'vehicles'
	if (key === 'discounts') {
		const modifiedObjValue = objValue.map((discount) => _.omit(discount, 'dateApplied'));
		const modifiedOthValue = othValue.map((discount) => _.omit(discount, 'dateApplied'));
		return _.isEqual(modifiedObjValue, modifiedOthValue);
	}
	// For other fields, return undefined to let lodash handle the comparison with its default behavior
}

export function getVariantOptionsFromVehicles(vehicles) {
	// variant options
	const variantOptions = {};
	vehicles.forEach((vehicle, index) => {
		variantOptions[`vehicle_${index}`] = { variant: vehicle.variantId };
	});
	return variantOptions;
}

// Perform deep comparison, except for the specified nested fields
export function comparePlacedOrderCreateInputs(objectA, objectB) {
	const objectsAreEqual = _.isEqualWith(
		objectA,
		objectB,
		// eslint-disable-next-line no-unused-vars
		(objValue, othValue, key, object, other, stack) => {
			if (_.isArray(objValue) && key === 'vehicles') {
				// Since vehicles is an array, iterate over its items for comparison
				return (
					objValue.length === othValue.length &&
					objValue.every((item, index) => {
						return _.isEqualWith(item, othValue[index], customComparator);
					})
				);
			}
			return customComparator(objValue, othValue, key);
		},
	);
	return objectsAreEqual;
}

export function createPaymentAccordionBody(
	cartCosts,
	oemProfileProps,
	selectedPaymentOption,
	setFinanceModalOpen,
	preQualModalData,
	setPreQualModalData,
	preQualErrorString,
	sectionAfterPaymentRef,
	paymentConfig,
	checkoutCart,
) {
	let innerFragement = [];
	switch (preQualModalData.status) {
		case PREQUAL_STATUSES.NOT_SUPPORTED:
			innerFragement = [
				<>
					<Button
						color='info'
						className='w-100'
						isOutline
						onClick={() =>
							sectionAfterPaymentRef.current?.scrollIntoView({ behavior: 'smooth' })
						}>
						Continue to financing application
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						Up to 84 months on any amount. Down payment is refundable until you are
						approved for and accept financing terms. Financing application collected
						below.{' '}
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		case PREQUAL_STATUSES.IN_PROGRESS: // Not doing anything because I want to treat it the same as the NOT_STARTED case
		case PREQUAL_STATUSES.NOT_STARTED:
			innerFragement = [
				<>
					<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
						<strong style={{ margin: 0 }}>
							Get pre-qualified in 30 seconds (OPTIONAL)
						</strong>
						<Icon
							icon='AccountBalance'
							color='info'
							styles={{ marginLeft: '0.5rem', width: '1.5rem', height: '1.5rem' }}
						/>
					</div>
					<br />
					See if you pre-qualify without affecting your credit score. <u>
						Not required
					</u>{' '}
					- you can also continue to the financing application without pre-qualifying.
					<br />
					<br />
					<Button
						color='info'
						className='w-100'
						// isOutline
						disabled={preQualModalData.status === PREQUAL_STATUSES.IN_PROGRESS}
						onClick={() => {
							const newPreQualData = {
								...preQualModalData,
								modalOpen: true,
								initModalPageName: PRE_QUAL_MODAL_PAGE_NAMES.INFO_COLLECT_PAGE, // 'infoCollectPage',
								status: PREQUAL_STATUSES.IN_PROGRESS,
							};
							setPreQualModalData(newPreQualData);
							track_checkout_prequal_modal_opened(
								oemProfileProps,
								cartCosts.totalCartCost,
								isPurchasePreorder(checkoutCart),
							);
						}}>
						{preQualModalData.status === PREQUAL_STATUSES.IN_PROGRESS ? (
							<Spinner isSmall color='light' style={{ margin: '0 !important' }} />
						) : (
							'Get pre-qualified'
						)}
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						Financing application is collected below. You can apply for terms of up to
						84 months on any amount. Not all customers will qualify.{' '}
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		case PREQUAL_STATUSES.APPROVED:
			innerFragement = [
				<>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<strong style={{ margin: 0 }}>YOU'RE PRE-QUALIFIED!</strong>
						<Icon
							icon='verified'
							color='info'
							styles={{ marginLeft: '0.5rem', width: '1.5rem', height: '1.5rem' }}
						/>
					</div>
					<br />
					Congratulations!{' '}
					<strong>
						We are temporarily holding your order slot for <u>20 minutes</u>
					</strong>
					. Submit your financing application below to guarantee your slot.
					<br />
					<br />
					<Button
						color='info'
						className='w-100'
						isOutline
						onClick={() =>
							sectionAfterPaymentRef.current?.scrollIntoView({ behavior: 'smooth' })
						}>
						Continue to financing application
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						This pre-qualification is not an approval or offer of credit, nor a
						guarantee of financing. If you would like to proceed with financing your
						vehicle purchase, please complete the financing application below.{' '}
						{cartCosts &&
						// Check if there's a down payment
						cartCosts.totalPossibleFinancingDownpayment > 0
							? ' Today’s down payment is refundable until you are approved for and accept financing terms. '
							: ' '}
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		case PREQUAL_STATUSES.DENIED:
			innerFragement = [
				<>
					<strong>Enter your info below to continue to the financing application</strong>
					<br />
					<br />
					We weren’t able to pre-qualify you. However, you are still able to submit a
					credit application below.
					<br />
					<br />
					<Button
						color='info'
						className='w-100'
						isOutline
						onClick={() =>
							sectionAfterPaymentRef.current?.scrollIntoView({ behavior: 'smooth' })
						}>
						Continue to financing application
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						Credit application is collected below.{' '}
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		default:
			return null;
	}
	if (preQualErrorString) {
		innerFragement.push(
			<>
				<br />
				<br />
				{/* Add some red text with the prequal error */}
				<strong style={{ color: 'var(--bs-form-invalid-color)' }}>
					{preQualErrorString}
				</strong>
			</>,
		);
	}
	return [
		<React.Fragment
			key={`${PAYMENT_CHOICES.LOAN_DOWNPAYMENT}-payment-accordion-${selectedPaymentOption}`}>
			{innerFragement}
		</React.Fragment>,
	];
}

export function getPaymentConfigOptions(checkoutCart, oemProfileProps) {
	// Preorder Checkout Case
	if (isPurchasePreorder(checkoutCart)) {
		if (checkoutCart?.customLinkConfig?.checkoutPaymentOverride) {
			// If the purchase is a preorder, make sure subset of valid preorder payment options
			const overrideOptions = Object.keys(
				checkoutCart.customLinkConfig.checkoutPaymentOverride,
			);
			const preorderPaymentOptions = [
				PAYMENT_CHOICES.PREORDER_RESERVATION,
				PAYMENT_CHOICES.PREORDER_VIP_RESERVATION,
			];
			const isSubset = overrideOptions.every((option) =>
				preorderPaymentOptions.includes(option),
			);
			if (!isSubset) {
				createLog(
					'Invalid-payment-config',
					'Checkout payment override options are not a subset of preorder payment options',
					'CRITICAL',
					{
						checkoutCartId: checkoutCart.id,
						oemKey: checkoutCart.oemKey,
					},
				);
				// return oem configured payment options by default
				return oemProfileProps.preorderPaymentConfig;
			}
			return checkoutCart.customLinkConfig.checkoutPaymentOverride;
		}
		return oemProfileProps.preorderPaymentConfig;
	}
	// Standard Checkout Case
	// 1. Check to see if there is payment override in checkoutCart
	if (checkoutCart?.customLinkConfig?.checkoutPaymentOverride) {
		// Validate that the options are a subset of OEM payment options
		const overrideOptions = Object.keys(checkoutCart.customLinkConfig.checkoutPaymentOverride);
		const oemPaymentOptions = Object.keys(oemProfileProps.paymentConfig);
		const isSubset = overrideOptions.every((option) => oemPaymentOptions.includes(option));
		if (!isSubset) {
			createLog(
				'Invalid-payment-config',
				'Checkout payment override options are not a subset of OEM payment options',
				'CRITICAL',
				{
					checkoutCartId: checkoutCart.id,
					oemKey: checkoutCart.oemKey,
				},
			);
			// return oem configured payment options by default
			return oemProfileProps.paymentConfig;
		}
		return checkoutCart.customLinkConfig.checkoutPaymentOverride;
	}
	// 2. If no payment override, return the OEM configured payment options
	return oemProfileProps.paymentConfig;
}

export function getVehicleCheckoutConfig(oemVehicle) {
	let checkoutConfig = DEFAULT_CHECKOUT_CONFIG;
	if (oemVehicle?.checkoutConfig) {
		checkoutConfig = {
			...DEFAULT_CHECKOUT_CONFIG,
			...oemVehicle.checkoutConfig,
		};
	}
	return checkoutConfig;
}

export async function createBuyerObjects(
	currBuyerEmail,
	buyerAddress,
	registeredUserProps,
	accountDetails,
	oem,
	setErrorStringAndHideAfterDelay,
	setNewUserCreated,
	setRegisteredUser,
) {
	let buyer;
	if (registeredUserProps === null || currBuyerEmail !== registeredUserProps.email) {
		const buyerFullName = accountDetails.fullName;
		// handle all clean up logic in this function
		// only create a buyer if the buyer exists
		buyer = await createOrGetAccountsForBuyer(
			accountDetails.emailAddress.toLowerCase(),
			// User data
			{
				fullName: buyerFullName,
				address: buyerAddress,
				oemId: oem.id,
				phoneNumber: extractPhoneNumber(accountDetails.phoneNumber),
			},
			// if errors come up, set the error string with informative message
			setErrorStringAndHideAfterDelay,
		);

		if ('error' in buyer) {
			setErrorStringAndHideAfterDelay(buyer.errorString);
		}
		// We use this to know whether the Stripe address element should re-render with the new user data.
		setNewUserCreated(true);
		// Set user context with newly created buyer obj
		setRegisteredUser(buyer);
	} else {
		// user is logged in, so we can just use their info
		buyer = registeredUserProps;
	}
	return buyer;
}

export function extractBuyerObjAddress(
	currBuyerEmail,
	userProps,
	checkoutCart,
	registrationAddress,
	selectedDeliveryOption,
	shippingAddressFormik,
	oemProfileProps,
	billingDetails,
) {
	let buyerAddress;
	if (userProps === null || currBuyerEmail !== userProps.email) {
		buyerAddress =
			isPurchasePreorder(checkoutCart) && !oemProfileProps.preorderCollectShippingAddress
				? {
						addressString: '',
						addressLineOne: '',
						addressLineTowo: '',
						city: '',
						state: '',
						zip: billingDetails?.address?.postal_code.toString() || '',
						county: '',
				  }
				: getBuyerAddress(
						registrationAddress,
						selectedDeliveryOption,
						shippingAddressFormik,
				  );
	} else {
		buyerAddress = userProps.address;
	}
	return buyerAddress;
}

// helper function to trigger an update to the abandoned cart object in the database
export function updateAbandonedCart(
	abandonedCartIdRef,
	checkoutCart,
	creatingAbandonedCart,
	setCreatingAbandonedCart,
	setAbandonedCartId,
	abandonedCartData,
	setAbandonedCartData,
	formikRefObjs,
	checkoutSection,
	additionalData = {},
	fieldValue = null,
	fieldId = null,
	formik = null,
) {
	if (!trackingEnabled()) {
		return;
	}
	// If we're seding in a field name and value, we want to update the abandoned cart if things have changed
	if (fieldId && formik) {
		const isValid = !formik.errors[fieldId];
		// First check if we need to create the abandoned cart object
		if (
			!abandonedCartIdRef.current &&
			checkoutCart.linkUseType !== 'SINGLE_USE' &&
			fieldId === 'emailAddress' &&
			!creatingAbandonedCart &&
			isValid
		) {
			// only create the abandoned cart if the email is valid and the link use type is multi use
			// set creatingAbandonedCart to true, Create the abandoned cart, set the abandonedCartId, and set creatingAbandonedCart to false
			setCreatingAbandonedCart(true);
			createAbandonedCart(
				checkoutCart.id,
				fieldValue,
				setAbandonedCartId,
				setCreatingAbandonedCart,
			);
			return;
		}
		const valueIsNotEmpty =
			fieldValue !== '' && fieldValue !== null && fieldValue !== undefined;
		if (!isValid || !valueIsNotEmpty) {
			return;
		}
	}
	// If we make it here we want to update the abandoned cart, but only if the abandoned cart id exists
	if (abandonedCartIdRef.current) {
		if (checkoutSection === CHECKOUT_SECTIONS.ORDER_DETAILS) {
			const {
				accountDetailsFormikRef,
				shippingAddressFormikRef,
				registrationAddressFormikRef,
			} = formikRefObjs;
			const {
				selectedDeliveryOption,
				selectedRegistrationOption,
				selectedPaymentOption,
				selectedPickUpLocation,
				regAddressSameAsShipping,
			} = additionalData;
			triggerOrderDetailsAbandonedCartUpdate(
				abandonedCartIdRef.current,
				accountDetailsFormikRef.current,
				shippingAddressFormikRef.current,
				registrationAddressFormikRef.current,
				selectedDeliveryOption,
				selectedRegistrationOption,
				selectedPaymentOption,
				selectedPickUpLocation,
				regAddressSameAsShipping,
				abandonedCartData,
				setAbandonedCartData,
			);
		}
		if (checkoutSection === CHECKOUT_SECTIONS.CREDIT_APP) {
			const { applicantDetailsFormikRef, applicantEmploymentFormikRef } = formikRefObjs;
			triggerCreditAppAbandonedCartUpdate(
				abandonedCartIdRef.current,
				applicantDetailsFormikRef.current,
				applicantEmploymentFormikRef.current,
				abandonedCartData,
				setAbandonedCartData,
			);
		}
	}
}

// helper function for creating user accounts & associated order
export async function createOrderDBObjs(
	placedOrder,
	enrichedVehicles,
	enrichedAccessories,
	buyerAddress,
	currBuyerEmail,
	accessoryCounts,
	cartCosts,
	currentPaymentIntent,
	prevOrderBody,
	savedPlacedOrderId,
	savedPurchasedVehicleIds,
	savedAdditionalDuesIds,
	setSavedPlacedOrderId,
	setSavedPurchasedVehicleIds,
	setSavedAdditionalDuesIds,
	setPrevOrderBody,
	setErrorStringAndHideAfterDelay,
	registeredUserProps,
	accountDetails,
	oem,
	setNewUserCreated,
	setRegisteredUser,
	returnVehicleMap,
	orderSection = CHECKOUT_SECTIONS.ORDER_DETAILS,
) {
	let response;
	let buyer;
	try {
		buyer = await createBuyerObjects(
			currBuyerEmail,
			buyerAddress,
			registeredUserProps,
			accountDetails,
			oem,
			setErrorStringAndHideAfterDelay,
			setNewUserCreated,
			setRegisteredUser,
		);
		// update the placedOrder object with buyer objects
		placedOrder.buyerId = buyer.id;

		const placedOrderReqBody = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			placedOrder,
			vehicles: enrichedVehicles,
			accessoryCounts: Object.fromEntries(accessoryCounts), // Convert to object so can pass through API call
			uniqueAccessories: Object.fromEntries(enrichedAccessories), // Convert to object so can pass through API call
			paymentIntentId: cartCosts.totalDueToday === 0 ? 'NONE' : currentPaymentIntent.id,
			ADDITIONAL_FIELDS_MAP,
			buyer,
			returnVehicleMap,
			orderSection,
		};

		if (
			prevOrderBody === null ||
			savedPlacedOrderId === null ||
			savedPurchasedVehicleIds === null ||
			!comparePlacedOrderCreateInputs(placedOrderReqBody, prevOrderBody)
		) {
			response = await createPlacedOrderObj(placedOrderReqBody);
			setSavedPlacedOrderId(response.placedOrderId);
			setSavedPurchasedVehicleIds(response.purchasedVehicleIds);
			setSavedAdditionalDuesIds(response.additionalDuesIds);
			setPrevOrderBody(placedOrderReqBody);
		} else {
			createLog(
				'Order-creation-skipped',
				'Re-using an existing order created from previous purchase attempt',
				'INFO',
				{
					paymentIntentId: currentPaymentIntent.id,
					placedOrderId: savedPlacedOrderId,
				},
			);
			response = {
				placedOrderId: savedPlacedOrderId,
				purchasedVehicleIds: savedPurchasedVehicleIds,
				additionalDuesIds: savedAdditionalDuesIds,
			};
		}
	} catch (e) {
		setErrorStringAndHideAfterDelay(
			"We're having difficulty processing your order at the moment. Please confirm that your card and billing details are correct. Otherwise, please try again.",
		);
		throw new Error(e);
	}
	const toReturn = {
		placedOrderId: response.placedOrderId,
		purchasedVehicleIds: response.purchasedVehicleIds,
		additionalDuesIds: response.additionalDuesIds,
		buyer,
	};
	if (returnVehicleMap) {
		toReturn.vehicleMap = response.vehicleMap;
	}
	return toReturn;
}

export function handleOrderPlaceSuccess(trackingData, redirectUrl) {
	if (typeof window.ttq === 'object') {
		// TikTok event tracking
		window.ttq.track('PlaceAnOrder', trackingData);
	}
	if (typeof window.fbq === 'function') {
		window.fbq('track', 'placeorder', trackingData);
	}
	if (typeof window.gtag === 'function') {
		window.gtag('event', 'conversion_event_purchase', {
			...trackingData,
			event_callback() {
				// eslint-disable-next-line no-unused-expressions
				isInIframe()
					? postRedirectMessage(redirectUrl)
					: (window.location.href = redirectUrl);
			},
		});
		return;
	}
	// redirect if not already done above
	// eslint-disable-next-line no-unused-expressions
	isInIframe() ? postRedirectMessage(redirectUrl) : (window.location.href = redirectUrl);
}

export async function createOrderRedirectUrl(
	oemkey,
	placedOrderId,
	purchasedVehicleIds,
	buyer,
	auth,
) {
	// Set up URL for redirect
	// const url = new URL(`${window.location.protocol}//${oemkey}.ekhodealer.com/vehicle-details`);
	// 'https://dev-buyer-portal-67669--stark-test-channel-id42s4uh.web.app/vehicle-details',

	// if the current env is dev, redirect to localhost:3001 instead of ${oemkey}.ekhodealer.com
	let urlPrefix = `${window.location.protocol}//${oemkey}.ekhodealer.com`;
	if (process.env.REACT_APP_CURR_ENV === ENV.DEVELOPMENT) {
		urlPrefix = 'http://localhost:3001';
	}
	const url = new URL(`${urlPrefix}/vehicle-details`);
	url.searchParams.append('placedOrderId', placedOrderId);
	url.searchParams.append('purchasedVehicleId', purchasedVehicleIds[0]);
	url.searchParams.append('email', buyer.email);
	// append idToken to search params if the current user is logged in and they are the buyer
	if (auth.currentUser) {
		const claimsBuyerId = (await auth.currentUser.getIdTokenResult()).claims.buyerId;
		if (auth.currentUser.uid === buyer.id || claimsBuyerId === buyer.id) {
			url.searchParams.append('idToken', await auth.currentUser.getIdToken());
		}
	}
	const redirectUrl = url.href;
	return redirectUrl;
}

// Function for sending out appropriate segment events and updating financing action item once a buyer has completed identity verification in financing
export async function handleCreditAppSubmissionTasks(data) {
	// buyer can submit: financing action item = SUBMIT_CREDIT_APP_AND_REPORT_DECISION, segment event = track_credit_application_submitted_by_buyer
	const { canSubmitCreditAppData, currentVehicle } = data;
	const { canSubmitCreditApp } = canSubmitCreditAppData;
	if (canSubmitCreditApp) {
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.SUBMIT_CREDIT_APP_AND_REPORT_DECISION,
		);
	}
	// buyer can't submit: financing action item = WAIT_FOR_SUBMISSION_WINDOW, segment event = track_credit_application_created_by_buyer
	else {
		const { daysUntilSubmission } = canSubmitCreditAppData;
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.WAIT_FOR_SUBMISSION_WINDOW,
			daysUntilSubmission,
		);
	}
}
